import { getAccessToken } from './utils';

export type APIConfiguration = {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: string;
  TOKEN: string | undefined;
  USERNAME: string | undefined;
  PASSWORD: string | undefined;
  HEADERS: string | undefined;
  ENCODE_PATH: string | undefined;
};

export const APIConfig: APIConfiguration = {
  BASE: '',
  VERSION: '1',
  WITH_CREDENTIALS: false,
  CREDENTIALS: 'include',
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};

export type ValidationErrorApiResponse = {
  timestamp: Date;
  details: Record<string, string>;
};

export type IdentityResponse = {
  access: string;
  userUuid: string; // TODO a uuid type?
  refresh: string;
  teamUuid: string; // TODO a uuid type?
  emailVerified: boolean;
  username: string;
};

export const getIdentity = () => {
  return fetch(APIConfig.BASE + '/api/identity', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  });
};

export const login = (username: string, password: string) => {
  return fetch(APIConfig.BASE + '/api/v1/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
};

export const register = (username: string, password: string) => {
  return fetch(APIConfig.BASE + '/api/v1/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password: password,
      firstName: 'John',
      lastName: 'Doe',
      companyName: 'Your Company',
    }),
  });
};

export const resendVerificationEmail = () => {
  return fetch(APIConfig.BASE + '/api/email/resend', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  });
};

export const verifyEmail = (token: string) => {
  return fetch(APIConfig.BASE + '/api/email/verify/' + token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  });
};

export const validateResetPasswordToken = (token: string) => {
  return fetch(APIConfig.BASE + '/api/v1/auth/resetpassword/' + token, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const resetPassword = (token: string, password: string) => {
  return fetch(APIConfig.BASE + '/api/v1/auth/resetpassword/' + token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      newPassword: password,
    }),
  });
};

export const requestResetPasswordEmail = (email: string) => {
  return fetch(APIConfig.BASE + '/api/v1/auth/resetpassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: email,
    }),
  });
};

export type KeepaliveTestResponse = {
  httpStatus: number;
  url: string;
  message: string;
  success: boolean;
};

export const keepUrlAlive = (url: string) => {
  return fetch(
    APIConfig.BASE +
      '/api/keepalive?' +
      new URLSearchParams({
        url: url,
      }),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAccessToken(),
      },
    }
  );
};

export type KeepaliveConfiguration = {
  url: string;
  status: string;
  errorNumber: number;
  updateDate: Date;
};

export const getCurrentConfig = () => {
  return fetch(APIConfig.BASE + '/api/keepalive', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  });
};

export const disableKeepalive = () => {
  return fetch(
    APIConfig.BASE +
      '/api/keepalive',
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAccessToken(),
      },
    }
  );
};