export const setAccessToken = (accessToken: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('access_token', accessToken);
  }
};

export const setEmailIsVerified = (emailIsVerified: boolean): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('email_verified', emailIsVerified.toString());
  }
};

export const getEmailIsVerified = (): boolean | null => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('email_verified') === 'true';
  }
  return null;
};

export const hasConfirmedEmail = (): boolean => {
  return !!getEmailIsVerified();
}

export const setLoggedInUser = (username: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('user', username);
  }
};

export const getLoggerInUser = (): string | null => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('user');
  }
  return null;
};

export const getAccessToken = (): string | null => {
  if (typeof window !== 'undefined') {
    const accessToken = window.localStorage.getItem('access_token');
    if (accessToken && !isJwtExpired(accessToken)) {
      return accessToken;
    }
    // TODO log out here when access token expires
  }
    return null;
};

export const getJwtExpiration = (jwt: string): number => {
  const parsedJwt = JSON.parse(window.atob(jwt.split('.')[1]));
  // Multiply by 1000 to convert from milliseconds to seconds
  return 1000 * parsedJwt.exp;
};

const isJwtExpired = (jwtToken: string) => {
  const expiration = getJwtExpiration(jwtToken);
  return expiration < Date.now();
};

export const logoutUserFromClient = (): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('email_verified');
    window.localStorage.removeItem('user');
  }
};

export const isUserLoggedIn = (): boolean => {
  return typeof window !== 'undefined' && hasValidAccessToken();
};

export const hasValidAccessToken = (): boolean => {
  return !!getAccessToken();
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
}
