import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import RouteGuard from '@/components/routeGuard'
import { APIConfig } from '@/lib/api';
import GoogleAnalytics from '@/components/googleAnalytics';
import Script from 'next/script';
import Head from 'next/head';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <GoogleAnalytics />
      <Head>
        <title>Free tier Keepalive</title>
      </Head>

      <RouteGuard>
        <Component {...pageProps} />
      </RouteGuard>
    </>
  );
}

export const getAPIUrl = (): string => {
  return process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:8080';
};

APIConfig.BASE = getAPIUrl();