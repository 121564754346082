import { useState, useEffect, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { redirect } from 'next/navigation';
import { hasValidAccessToken, hasConfirmedEmail } from '../lib/utils';
import { RedirectType } from 'next/dist/client/components/redirect';

export default function RouteGuard({ children }: PropsWithChildren) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    const isAuthorized = authCheck(router.asPath);
    if (isAuthorized) {
      emailConfirmationCheck(router.asPath);
    }

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on('routeChangeStart', hideContent);

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck);
    router.events.on('routeChangeComplete', emailConfirmationCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
      router.events.off('routeChangeComplete', emailConfirmationCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function authCheck(url: string) {
    const publicPaths = ['/login', '/register', '/reset-password'];
    const path = url.split('?')[0];
    let isAuthorized = false;
    if (!hasValidAccessToken() && !publicPaths.includes(path)) {
      setAuthorized(false);
      router.replace('/login');
    } else {
      isAuthorized = true;
      setAuthorized(isAuthorized);
    }
    return isAuthorized;
  }

  function emailConfirmationCheck(url: string) {
    const publicPaths = [
      '/verify-email',
      '/logout',
      '/login',
      '/reset-password',
      '/register',
    ];
    const path = url.split('?')[0];
    if (!hasConfirmedEmail() && !publicPaths.includes(path)) {
      setEmailConfirmed(false);
      router.push({
        pathname: '/verify-email',
      });
    } else {
      setEmailConfirmed(true);
    }
  }

  return authorized && children;
}
