import Script from 'next/script'

function GoogleAnalytics() {
    if (process.env.NODE_ENV !== 'production') {
        return <></>
    }
    return (
        <>
            <Script key="gscript" async strategy="lazyOnload" src="https://www.googletagmanager.com/gtag/js?id=G-VRL20BE62R"></Script>
            <Script key="google-analytics" strategy="lazyOnload" id="google-analytics">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-VRL20BE62R');
                `}
            </Script>
        </>
    )
}

export default GoogleAnalytics